import SignaturePad from 'signature_pad'

window.initSignaturePad = function initSignaturePad(id, clear, input) {
    const canvas = document.getElementById(id);
    const inputElem = document.querySelector(input)
    function resizeCanvas() {
        const ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
    }

    window.onresize = resizeCanvas;
    resizeCanvas();

    const signaturePad = new SignaturePad(canvas, {
        backgroundColor: 'rgb(246, 249, 245)'
    });

    ['touchend', 'click'].forEach(event => {
        canvas.addEventListener(event, function (e) {
            e.preventDefault()
            // if (signaturePad.isEmpty()) {
            //     return alert("Please provide a signature first.");
            // }
            const dataPng = signaturePad.toDataURL('image/png');
            inputElem.value = dataPng
        });
    })

    document.getElementById(clear).addEventListener('click', function (e) {
        e.preventDefault()
        signaturePad.clear();
    });

}