const tabBtns = document.querySelectorAll('.industries__tab');
const tabContents = document.querySelectorAll('.industries__text');
const imgContents = document.querySelectorAll('.industries__img');

tabBtns.forEach((tabBtn, i) => {
    tabBtn.addEventListener('click', () => {
        if (!tabBtn.classList.contains('is-active')) {

            [tabBtns, tabContents, imgContents].forEach(arr => {
                arr.forEach(elem => {
                    elem.classList.remove('is-active');
                })
            })
            
            tabBtn.classList.add('is-active');
            tabContents[i].classList.add('is-active');
            imgContents[i].classList.add('is-active');
        }
    })
})