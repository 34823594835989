const customAccordion = (elements, trigger) => {
    elements.forEach(accordion => {
        const triggerBtn = accordion.querySelector(trigger);
        triggerBtn.addEventListener('click', () => {
            accordion.classList.toggle('is-active');
        })
    })
}

if (document.querySelector(".job-list__accordion-box")) {
    const accordions = document.querySelectorAll('.job-list__accordion-box');
    customAccordion(accordions, '.job-list__head')
}

if (document.querySelector(".map__accordion")) {
    const accordions = document.querySelectorAll('.map__accordion');
    customAccordion(accordions, '.map__accordion-head')
}
