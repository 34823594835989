import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './utils/remove-inline-styles.utils.js';

import './libs/lottie.js';
import './libs/simplebar.js';
import './libs/aos.js';
import './libs/plyr.js';
import './libs/parallax.js';
import './libs/swiper.js';
import './libs/choices.js';
import './libs/countUp.lib.js';
import './libs/signature.lib.js';
import './libs/datepicker.lib.js';
import './libs/overlayScrollbars.js';
import './libs/cookiesconsent.js';

import './components/header.component.js';
import './components/scrollBtn.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';
// import './components/singleRangeSlider.component.js';
import './components/customTabs.component.js';
import './components/customAccordion.component.js';
import './components/map.component.js';
import './components/nameCut.component.js';
