import SmoothScroll from 'smooth-scroll';
import Swiper, { EffectFade, FreeMode, Navigation, Thumbs } from 'swiper';
import SimpleBar from 'simplebar';

const classToggler = (selectors) => {
  const containers = document.querySelectorAll(selectors);

  containers.forEach((container) => {
    const scrollableContainer = new SimpleBar(container);
    const scrollBar = container.querySelector(
      '.simplebar-vertical .simplebar-scrollbar'
    );

    if (scrollBar.style.display === 'none') {
      container.classList.add('is-bottom');
    }

    scrollableContainer
      .getScrollElement()
      .addEventListener('scroll', function () {
        let isAtBottom =
          this.scrollTop + this.clientHeight + 10 >= this.scrollHeight;

        if (isAtBottom) {
          container.classList.add('is-bottom');
        } else {
          container.classList.remove('is-bottom');
        }
      });
  });
};

function currentMapHandler(currentMapIds, simplebarWrappers) {
  currentMapIds.forEach((currentArea, index) => {
    currentArea.addEventListener('click', () => {
      if (innerWidth < 1024) {
        new SmoothScroll().animateScroll(
          document.querySelector('#map__ancor'),
          { speed: 500 }
        );
      }

      currentMapIds.forEach((area, i) => {
        area.classList.remove('is-active');
      });

      currentArea.classList.add('is-active');
    });
  });
}

const mapElement = document.querySelector('.map');
if (mapElement) {
  const mainMapContainer = mapElement.querySelector('.map__map-gb');
  const mainMap = mapElement.querySelector('#map_gb');
  const mainMapParts = mainMap.querySelectorAll('g');
  const areasContainer = mapElement.querySelector('.map__areas');
  const areaMap = mapElement.querySelector('.map__area');
  const closeBtn = mapElement.querySelector('.map__close');
  const sliderTabsWrapper = mapElement.querySelector(
    '.map__thumb .swiper-wrapper'
  );
  const sliderContentWrapper = mapElement.querySelector(
    '.map__slider .swiper-wrapper'
  );

  // init Swiper
  const thumbSlider = new Swiper('.map__thumb', {
    modules: [FreeMode, Navigation],
    spaceBetween: 5,
    slidesPerView: 1,
    freeMode: true,
    watchSlidesProgress: true,
    breakpoints: {
      568: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      900: {
        slidesPerView: 4,
      },
      1024: {
        spaceBetween: 8,
        slidesPerView: 'auto',
      },
    },
  });

  const mainSlider = new Swiper('.map__slider', {
    modules: [Navigation, Thumbs, EffectFade],
    spaceBetween: 10,
    effect: 'fade',
    navigation: {
      nextEl: '.map__arrow--next',
      prevEl: '.map__arrow--prev',
    },
    thumbs: {
      swiper: thumbSlider,
    },
    allowTouchMove: false,
    noSwiping: true,
  });

  // generate slides thumbs
  const topBarSlides = locations.map((region, i) => {
    return `
            <div class="swiper-slide">
                ${region.title}
            </div>
        `;
  });

  sliderTabsWrapper.innerHTML = topBarSlides.join('');
  const decodeHtmlEntities = (text) => {
    const entities = {
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&quot;': '"',
      '&#39;': "'",
      '&#x2F;': '/',
      '&#x60;': '`',
      '&#x3D;': '=',
    };

    return text.replace(/&(amp|lt|gt|quot|#39|#x2F|#x60|#x3D);/g, (match) => {
      return entities[match] || match;
    });
  };
  // generate slides content
  const contentSlides = locations.map(({ title, content }, i) => {
    console.log(title, content);
    return `
            <div class="swiper-slide">
                <div class="map__card flex flex-col">
                    <div class="map__scroll">
                      <div class="map__region">
                         ${title}
                      </div>
                      <div class="details__text map__subtitle">
                          ${decodeHtmlEntities(content)}
                      </div>
                   </div>
                </div>
            </div>
       `;
  });

  sliderContentWrapper.innerHTML = contentSlides.join('');

  //    update Swiper and SimpleBar when content changes
  mainSlider.update();

  const simplebarWrappers = document.querySelectorAll(
    '.map__slider .js-scroll-wrap'
  );
  if (simplebarWrappers) {
    simplebarWrappers.forEach((scrollWrap) => {
      new SimpleBar(scrollWrap);
    });
  }

  const currentMainMapPath = document.querySelectorAll(`#map_gb>g`);
  currentMainMapPath.forEach((region, i) => {
    const regionId = region.getAttribute('id');
    const regionPaths = region.querySelectorAll('path');
    locations.forEach((location) => {
      if (location.custom_id === regionId) {
        regionPaths.forEach((path) => {
          path.style.fill = `${location.color}`;
        });
      }
    });
  });

  const mapLogic = ({ activeIndexRef, regionIdRef }) => {
    let activeIndex = activeIndexRef;
    let regionId = regionIdRef;

    if (typeof regionIdRef === 'string') {
      activeIndex = locations.findIndex(
        (region) => region.custom_id == regionId
      );
    } else if (typeof activeIndexRef === 'number') {
      regionId = locations[activeIndex].custom_id;
    }

    areaMap.innerHTML = locations[activeIndex].svg_path;
    areasContainer.classList.add('is-active');
    mainMapContainer.classList.add('is-disabled');

    const currentMapIds = areaMap.querySelectorAll(`#${regionId}>g`);
    const currentMapPaths = areaMap.querySelectorAll('path');
    currentMapPaths.forEach((path) => {
      path.style.fill = `${locations[activeIndex].color}`;
    });

    const slides = sliderContentWrapper.querySelectorAll('.swiper-slide');
    const simplebarWrappers = slides[activeIndex].querySelector(
      '.simplebar-content-wrapper'
    );

    return {
      currentMapIds: new Array(...currentMapIds),
      slides,
      simplebarWrappers,
      activeIndex,
      regionId,
    };
  };

  mainSlider.on('slideChange', (slide) => {
    const { currentMapIds, slides, simplebarWrappers } = mapLogic({
      activeIndexRef: slide.activeIndex,
    });

    currentMapHandler(currentMapIds, simplebarWrappers);
  });

  mainMapParts.forEach((mainMapPart) => {
    mainMapPart.addEventListener('click', () => {
      const { currentMapIds, slides, simplebarWrappers, activeIndex } =
        mapLogic({
          regionIdRef: mainMapPart.id,
        });
      mainSlider.slideTo(activeIndex);

      currentMapHandler(currentMapIds, simplebarWrappers);
    });
  });

  closeBtn.addEventListener('click', () => {
    mainMapContainer.classList.remove('is-disabled');
    areasContainer.classList.remove('is-active');
  });
}
classToggler('.map__scroll');
