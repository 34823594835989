import scrollLock from 'scroll-lock';

const burgerMenu = (menuSelector, burgerSelector, layout) => {
  const menuElem = document.querySelector(menuSelector),
  layoutElem = document.querySelector(layout),
  burgerElem = document.querySelectorAll(burgerSelector);

  const removeClass = () => {
      layoutElem.classList.remove("active");
      menuElem.classList.remove("active");
      scrollLock.enablePageScroll();
  };
  removeClass();

  menuElem.querySelectorAll("a").forEach(i => {
      i.addEventListener("click", e => {
          if (menuElem.classList.contains("active")) {
              removeClass();
          }
      });
  });
  menuElem.querySelectorAll("button").forEach(i => {
      i.addEventListener("click", e => {
          e.preventDefault()
          if (menuElem.classList.contains("active")) {
              removeClass();
          }
      });
  });

  burgerElem.forEach(burgerBtn => {
      burgerBtn.addEventListener("click", () => {
          if (menuElem.classList.contains("active")) {
              removeClass();
          } else {
              menuElem.classList.add("active");
              layoutElem.classList.add("active");
              var method = 'margin'; //Available methods: none, padding, margin, width, max-width
              scrollLock.setFillGapMethod(method);
              scrollLock.disablePageScroll();
  
              if (document.querySelector(".js-scroll-wrap") && innerWidth < 1024) { 
                  scrollLock.addScrollableSelector('.simplebar-content-wrapper'); 
              } 
  
              scrollLock.addFillGapSelector('.header__navbar-box'); 
              scrollLock.addFillGapSelector('.job-list__filter-box__navbar-box'); 
  
          } 
      }); 
  })
  

  window.addEventListener("resize", ()=> {
  if (innerWidth > 1024) {
      removeClass();
  }
  });

  layoutElem.addEventListener("click", () => {
      removeClass();
  });
};

function stickyMenu () {
  const headerElem = document.querySelector(".header");

  if (window.pageYOffset >= 50) {
      headerElem.classList.add("sticky");
  } else {
      headerElem.classList.remove("sticky");
  }
}

window.addEventListener("scroll", stickyMenu);
stickyMenu();

if (document.querySelector(".header")) {
  burgerMenu(".header", ".header__burger", ".header__layout");
}
if (document.querySelector(".job-list__filter-box")) {
  burgerMenu(".job-list__filter-box", ".job-list__burger-box", ".job-list__layout");
}