import lottie from 'lottie-web/build/player/lottie_light.min.js';

const aboutDecor = document.querySelector('.about-us__decor');
const resourcesSection = document.querySelector('.resources');
const resourcesDecor = document.querySelector('.resources__decor');

function activeOnView(element, anim, animChilds = false) {
  if (
    element.getBoundingClientRect().top < innerHeight / 2 &&
    element.getBoundingClientRect().bottom > innerHeight / 2 &&
    !element.classList.contains('active')
  ) {
    element.classList.add('active');
    anim.playSegments([0, 60], true);
    // anim.setSpeed(0.9)

    if (animChilds) {
      const childs = document.querySelectorAll(animChilds);
      childs.forEach((elem) => {
        elem.classList.add('active');
      });
    }
  }
}

if (resourcesSection) {
  let resourcesLottie = lottie.loadAnimation({
    container: resourcesDecor,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: site_dir + '/src/js/json/about-decor.json',
  });
  activeOnView(resourcesSection, resourcesLottie);

  window.addEventListener('scroll', () => {
    activeOnView(resourcesSection, resourcesLottie);
  });
}
if (aboutDecor) {
  let aboutLottie = lottie.loadAnimation({
    container: aboutDecor,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: site_dir + '/src/js/json/about-decor.json',
  });

  activeOnView(aboutDecor, aboutLottie, '.about-us__btn');

  window.addEventListener('scroll', () => {
    activeOnView(aboutDecor, aboutLottie, '.about-us__btn');
  });
}
