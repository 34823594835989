import SmoothScroll from 'smooth-scroll';

function scrollBtn() {
    const scroll = new SmoothScroll();
    const scrollTrigger = document.querySelector('.scroll-btn');
    const heroSection = document.querySelector('.hero');
    const secondSection = document.querySelectorAll('section')[1];

    function scrollToTop() {
        if(scrollTrigger.classList.contains('is-scrolled')) {
            scroll.animateScroll(heroSection, scrollTrigger, { speed: 300});
        }
        if(!scrollTrigger.classList.contains('is-scrolled')) {
            scroll.animateScroll(secondSection, scrollTrigger, { offset: 97, speed: 300});
        }
    }
 
    window.addEventListener("scroll", () => {
        if (window.scrollY > heroSection.offsetHeight / 2 && !scrollTrigger.classList.contains('is-scrolled')) {
            scrollTrigger.classList.add('is-scrolled')
        } 
        if (window.scrollY <= 200 && scrollTrigger.classList.contains('is-scrolled')) {
            scrollTrigger.classList.remove('is-scrolled')
        }
    });

    scrollTrigger.addEventListener('click', scrollToTop);
}

scrollBtn();