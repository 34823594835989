import Aos from 'aos';

Aos.init({
	startEvent: 'load',
	offset: innerWidth > 640 ? 150 : 50,
	delay: 0,
	duration: 500,
	easing: "ease",
	once: true,
});
