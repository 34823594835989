function removeAllInlineStyles() {
  const elements = document.querySelectorAll('[data-remove-inline-styles]');
  elements.forEach((el) => {
    const allChildren = el.querySelectorAll('*');
    allChildren.forEach((child) => {
      child.removeAttribute('style');
    });
  });
}

removeAllInlineStyles();
