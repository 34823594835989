import {
    OverlayScrollbars,
} from 'overlayscrollbars';

const scrollContainers = document.querySelectorAll('.table-container')

if (scrollContainers.length) {
    scrollContainers.forEach(container => {
        const osInstance = OverlayScrollbars(container, {});
    })
}
