import Swiper, { Autoplay, EffectFade, FreeMode, Grid, Navigation, Pagination, Scrollbar, Thumbs} from 'swiper';

if (document.querySelector('.values__slider')) {
	const swiper = new Swiper('.values__slider', {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
		spaceBetween: 15,
		speed: 500,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".values__btn--prev",
            nextEl: ".values__btn--next",
		},
		breakpoints: {
            568: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 40,
                initialSlide: 2,
            },
		},
	});
}

if (document.querySelector('.team__slider')) {
	const swiper = new Swiper('.team__slider', {
		modules: [Navigation, Autoplay, EffectFade],
		slidesPerView: 1,
		spaceBetween: 15,
        effect: "fade",
		speed: 500,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".team .slider-nav__btn--prev",
            nextEl: ".team .slider-nav__btn--next",
		},
	});
}

if (document.querySelector('.benefits__slider')) {
	const swiper = new Swiper('.benefits__slider', {
		modules: [Navigation, Autoplay, Scrollbar],
		slidesPerView: 1,
		spaceBetween: 15,
		speed: 500,
		autoplay: {
            delay: 8000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
        scrollbar: {
            el: ".benefits .swiper-scrollbar",
            draggable: true,
        },

		navigation: {
            prevEl: ".benefits .slider-nav__btn--prev",
            nextEl: ".benefits .slider-nav__btn--next",
		},
		breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
		},
	});
}

if (document.querySelector('.shoes__slider')) {
	const swiper = new Swiper('.shoes__slider', {
		modules: [Navigation, Autoplay, Scrollbar],
		slidesPerView: 1,
		spaceBetween: 8,
		speed: 500,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
        scrollbar: {
            el: ".shoes .swiper-scrollbar",
            draggable: true,
        },

		navigation: {
            prevEl: ".shoes .slider-nav__btn--prev",
            nextEl: ".shoes .slider-nav__btn--next",
		},
		breakpoints: {
            768: {
                spaceBetween: 20,
            },
            1800: {
                slidesPerView: 2,
            },
		},
	});
}

if (document.querySelector('.process__slider')) {
	const swiper = new Swiper('.process__slider', {
		modules: [Navigation, Autoplay, Scrollbar],
		slidesPerView: 1,
		spaceBetween: 8,
		speed: 500,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
        scrollbar: {
            el: ".process .swiper-scrollbar",
            draggable: true,
        },
		navigation: {
            prevEl: ".process .slider-nav__btn--prev",
            nextEl: ".process .slider-nav__btn--next",
		},
		breakpoints: {
            768: {
                spaceBetween: 16,
            },
		},
	});
}

if (document.querySelector(".franchise__slider")) {
    const swiper = new Swiper(`.franchise__slider`, {
        modules: [Grid, Autoplay],
        grabCursor: true,
        slidesPerView: 1,
        spaceBetween: 24,
        grid: {
            rows: 2,
        },
        speed: 500,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            569: {
            slidesPerView: 2,
            grid: {
                rows: 2,
            },
            },
            1024: {
            slidesPerView: 3,
            grid: {
                rows: 2,
            },
            },
        },
    });
  }


if (document.querySelector('.jobs__slider')) {
	const swiper = new Swiper('.jobs__slider', {
		modules: [Navigation, Autoplay],
		direction: 'vertical',
		slidesPerView: 4,
		spaceBetween: 20,
		speed: 500,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
		prevEl: ".jobs .slider-nav__btn--prev",
		nextEl: ".jobs .slider-nav__btn--next",
		},
		breakpoints: {
            768: {
                spaceBetween: 60,
            },
		},
	});
}

if (document.querySelector('.jobs__swiper')) {
	const swiper = new Swiper('.jobs__swiper', {
		modules: [Navigation, Autoplay],
		direction: 'vertical',
		slidesPerView: 3,
		spaceBetween: 20,
		speed: 500,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
		prevEl: ".jobs .slider-nav__btn--prev",
		nextEl: ".jobs .slider-nav__btn--next",
		},
		breakpoints: {
            1024: {
                spaceBetween: 40,
            },
		},
	});
}

if (document.querySelector('.clients__slider')) {
	const swiper = new Swiper('.clients__slider', {
		modules: [Navigation, Autoplay],
		slidesPerView: 1,
		spaceBetween: 16,
		speed: 500,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
		navigation: {
            prevEl: ".clients .slider-nav__btn--prev",
            nextEl: ".clients .slider-nav__btn--next",
		},
        breakpoints: {
            568: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
        },
	});
}

if (document.querySelector('.studies__slider')) {
	const swiper = new Swiper('.studies__slider', {
		modules: [Navigation, Pagination, EffectFade, Autoplay],
		slidesPerView: 5,
		spaceBetween: 16,
        effect: "fade",
		speed: 500,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
		},
        pagination: {
            el: ".studies .swiper-pagination",
            type: "fraction",
        },
		navigation: {
            prevEl: ".studies .slider-nav__btn--prev",
            nextEl: ".studies .slider-nav__btn--next",
		},
	});
}

if (document.querySelector('.ready__slider')) {
	const swiper = new Swiper('.ready__slider', {
		modules: [Navigation, EffectFade, Autoplay],
		slidesPerView: 5,
		spaceBetween: 16,
        effect: "fade",
		speed: 500,
        autoHeight: true,
        allowTouchMove: false,
        noSwiping: true,
	});
}

window.initFranchiselider = function initFranchiselider() {
    if (document.querySelector('.franchise-popup__slider')) {
        const swiper = new Swiper('.franchise-popup__slider', {
            modules: [Navigation, EffectFade, Pagination, Autoplay],
            slidesPerView: 5,
            spaceBetween: 16,
            effect: "fade",
            speed: 500,
            autoHeight: true,
            allowTouchMove: false,
            noSwiping: true,
            navigation: {
                prevEl: ".franchise-popup__btn--prev",
                nextEl: ".franchise-popup__btn--next",
            },
            pagination: {
                el: ".franchise-popup__pagination.swiper-pagination",
                type: "progressbar",
            },
        });
    }
}

window.initChoicesSlider = function initChoicesSlider() {
    if (document.querySelector('.choice-us__slider')) {
        const swiper = new Swiper('.choice-us__slider', {
            modules: [Navigation, EffectFade, Autoplay],
            slidesPerView: 5,
            spaceBetween: 16,
            effect: "fade",
            speed: 500,
            autoHeight: true,
            allowTouchMove: false,
            noSwiping: true,
            navigation: {
                prevEl: ".choice-us__btn--prev",
                // nextEl: ".choice-us__btn--next",
            },
        });

        const prevBtn = document.querySelector(".choice-us__btn--prev");
        const btnToCandidate = document.querySelector('.choice-us .ready__input--candidate');
        const btnToClient = document.querySelector('.choice-us .ready__input--client');
        const btnSubmitCandidate = document.querySelector('#apply_form_submit_candidate');
        const btnSubmit = document.querySelector('#apply_form_submit');

        function slideToForm(btn, formIndex) {
            btn.addEventListener('click', (e) => {
                const input = btn.querySelector('input')
                e.preventDefault();
                input.checked = true;
                swiper.slideTo(formIndex);
            })
        }

        slideToForm(btnToCandidate, 2)
        slideToForm(btnToClient, 1)

        btnSubmitCandidate.addEventListener('click', () => {
            setTimeout(() => {
                swiper.update();
            }, 1500)
        })
        btnSubmit.addEventListener('click', () => {
            setTimeout(() => {
                swiper.update();
            }, 1500)
        })

        prevBtn.addEventListener('click', () => {
            swiper.slideTo(0);
            [btnToCandidate, btnToClient].forEach(inputBox => {
                const input = inputBox.querySelector('input')
                input.checked = false;
            })
        })
    }
}


if (document.querySelector('.testimonials__slider')) {
    new Swiper('.testimonials__slider', {
        modules: [Navigation, Autoplay],
        slidesPerView: 1,
        spaceBetween: 10,
        speed: 500,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
            prevEl: ".testimonials .slider-nav__btn--prev",
            nextEl: ".testimonials .slider-nav__btn--next",
        },
        breakpoints: {
            900: {
                spaceBetween: 25,
                slidesPerView: 2,
            },
            1537: {
                spaceBetween: 25,
                slidesPerView: 3,
            },
        },
    });
}

if (document.querySelector('.solutions__slider')) {
    const thumbSlider = new Swiper(".solutions__slider", {
        modules: [FreeMode, Navigation, Thumbs],
        slidesPerView: 1,
        spaceBetween: 10,
        watchSlidesProgress: true,
        freeMode: true,
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 15,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
        },
    });

    const mainSlider = new Swiper(".solutions__hidden-slider", {
        modules: [Autoplay, FreeMode, Navigation, Thumbs, EffectFade],
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
            prevEl: ".solutions .slider-nav__btn--prev",
            nextEl: ".solutions .slider-nav__btn--next",
        },
        thumbs: {
            swiper: thumbSlider,
        },
        speed: 500,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
    });
}
if (document.querySelector('.resources__slider')) {
    new Swiper('.resources__slider', {
        modules: [Navigation, Autoplay],
        slidesPerView: 1,
        spaceBetween: 30,
        speed: 500,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
            prevEl: ".resources .slider-nav__btn--prev",
            nextEl: ".resources .slider-nav__btn--next",
        },
        breakpoints: {
            768: {
                spaceBetween: 60,
                slidesPerView: 2,
            },
            1537: {
                spaceBetween: 60,
                slidesPerView: 3,
            },
          },
    });
}
